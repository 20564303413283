import React, { createContext, useContext, useEffect, useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { getUserData } from '../database/indexedDB';

export const BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
};

export const getInitials = (fullName) => {
    if (!fullName) return '';
    const nameParts = fullName.split(' ');
    return nameParts.map(part => part[0].toUpperCase()).join('');
};

export const formatDateTime = (dateTimeStr, includeTime = true) => {
    if (!dateTimeStr) return '';
    
    const cleanedDateTimeStr = dateTimeStr.replace('.', ':').replaceAll('/', '-');
    let date, time;
    let day, month, year;
    let hours = 0, minutes = 0, seconds = 0;

    
    if (cleanedDateTimeStr.includes('-') && cleanedDateTimeStr.split(' ')[0].split('-')[0].length === 4) {
        
        [date, time] = cleanedDateTimeStr.split(' ');
        [year, month, day] = date.split('-').map(Number);
    } else {
      
        [date, time] = cleanedDateTimeStr.split(' ');
        let [part1, part2, part3] = date.split('-').map(Number);

        if (part3 >= 1000) {
           
            [day, month, year] = [part1, part2, part3];
        } else if (part1 <= 12) {
            
            [month, day, year] = [part1, part2, part3];
        } else {
           
            [day, month, year] = [part1, part2, part3];
        }
    }

    if (time) {
        [hours, minutes, seconds] = time.split(':').map(Number);
    }
    
    const parsedDate = new Date(year, month - 1, day, hours, minutes, seconds);
    const formattedDate = `${String(parsedDate.getDate()).padStart(2, '0')} ${parsedDate.toLocaleString('en-US', { month: 'short' })}, ${parsedDate.getFullYear()}`;
    const formattedTime = `${parsedDate.getHours() % 12 || 12}:${String(parsedDate.getMinutes()).padStart(2, '0')} ${parsedDate.getHours() >= 12 ? 'PM' : 'AM'}`;
    return includeTime ? `${formattedDate} ${formattedTime}` : formattedDate;
};

export const formatOnlyDate = (dateStr) => {
    if (typeof dateStr !== 'string') return dateStr;
    const date = new Date(dateStr);
    if (isNaN(date.getTime())) return dateStr;
    return `${date.getDate()} ${date.toLocaleString('en-US', { month: 'short' })}, ${date.getFullYear()}`;
};

export const currencyFormat = (amount,currency = 'USD', includeDecimal = true) => {
    if (amount == null || ['NULL', 'N/A', '', 0, '0', '00', '0.00'].includes(amount)) {
        return 'N/A';
    }
    const num = parseFloat(amount);
    return num.toLocaleString('en-US', {
        style: 'currency',
        currency: currency,
        minimumFractionDigits: includeDecimal ? 2 : 0,
        maximumFractionDigits: includeDecimal ? 2 : 0
    });
};

export const login = (data) => {
    localStorage.setItem('user_data', JSON.stringify(data));
};

export const updateThemeColor = (color) => {
    let metaThemeColor = document.querySelector('meta[name="theme-color"]');
    if (!metaThemeColor) {
        metaThemeColor = document.createElement('meta');
        metaThemeColor.name = 'theme-color';
        document.head.appendChild(metaThemeColor);
    }
    metaThemeColor.content = color;
};

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [userData, setUserData] = useState(null);
    const navigate = useNavigate();

    const fetchData = useCallback(async () => {
        try {
            const data = await getUserData();
            if (data) {
                setUserData(data);
                const redirectPaths = ['/', '/register', '/reset'];
                if (redirectPaths.includes(window.location.pathname)) {
                    navigate('/dashboard');
                }
            } else {
                handleUnauthenticated();
            }
        } catch (error) {
            console.error('Error fetching user data:', error);
        }
    }, [navigate]);

    const handleUnauthenticated = () => {
        const restrictedPaths = ['/', '/register', '/reset'];
        if (!restrictedPaths.includes(window.location.pathname)) {
            navigate('/');
        }
    };

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    const logout = () => {
        localStorage.removeItem('user_data');
        setUserData(null);
        navigate('/');
    };

    const register = (user) => {
        setUserData(user);
        navigate('/dashboard');
    };

    return (
        <AuthContext.Provider value={{ userData, login, register, logout }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => useContext(AuthContext);

